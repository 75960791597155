import React, { useState } from 'react';
import { Button } from '@mui/material';

const SubListing = () => {
  const [formErrors, setFormErrors] = useState({});

  const [inputFields, setInputFields] = useState([
    {
      plan_inable: '',
      bedrooms: '',
      bathrooms: '',
      property_title: '',
      postfix: '',
      price: '',
      price_postfix: '',
      property_type: '',
      availability: ''
    }
  ]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputFields = [...inputFields];
    newInputFields[index][name] = value;
    setInputFields(newInputFields);
  };
  const [success, setSuccess] = useState(null);

  const handleAddInputField = () => {
    const newInputField = {
      plan_inable: '',
      bedrooms: '',
      bathrooms: '',
      property_title: '',
      postfix: '',
      price: '',
      price_postfix: '',
      property_type: '',
      availability: ''
    };
    setInputFields([...inputFields, newInputField]);
  };

  const handleRemoveInputField = (index) => {
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
  };

  const handleClick = () => {
    console.log('Form data:', inputFields);
  };
    
  const inputField = {
    plan_inable: '',
    property_title: '',
    property_type: '',
    availability: ''
  };
  const validateForm = () => {
    const errors = {};
    if (!inputField.plan_inable) {
      errors.plan_inable = "plan_inable is required";
    }
    if (!inputField.property_title) {
      errors.property_title = "property_title is required";
    }
    if (!inputField.property_type) {
      errors.property_type = "property_type is required";
    }
    if (!inputField.availability) {
      errors.availability = "availability is required";
    }
   
    // Add additional validations as needed
  
    return errors;
  };
  const handleNext = async (event) => {
    event.preventDefault();
   
    const errors = validateForm(inputField);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors); // Set errors if validation fails
    } 
    else {
      console.log("Form data submitted:", inputField);

    const formDataObj = new FormData();
    inputFields.forEach((inputField) => {
      formDataObj.append('plan_inable', inputField.plan_inable);
      formDataObj.append('bedrooms', inputField.bedrooms);
      formDataObj.append('bathrooms', inputField.bathrooms);
      formDataObj.append('property_title', inputField.property_title);
      formDataObj.append('postfix', inputField.postfix);
      formDataObj.append('price', inputField.price);
      formDataObj.append('price_postfix', inputField.price_postfix);
      formDataObj.append('property_type', inputField.property_type);
      formDataObj.append('availability', inputField.availability);
    });
    console.log('Form data:', [...formDataObj.entries()]);
  
    const requestOptions = {
      method: 'POST',
      body: formDataObj,
      headers: { 'Content-Type': 'multipart/form-data' },
      redirect: 'follow',
    };
  
    const response = await fetch('https://9eb7-139-135-43-100.ngrok-free.app/sublistings', requestOptions);
  
    if (response.ok) {
      const result = await response.json();
      console.log('Property added successfully', result); 
      setSuccess(result.message);
      // Reset form fields
      setInputFields([
        {
          plan_inable: '',
          bedrooms: '',
          bathrooms: '',
          property_title: '',
          postfix: '',
          price: '',
          price_postfix: '',
          property_type: '',
          availability: ''
        }
      ]);
    } else {
      const result = await response.text();
      console.error('Error adding property', result);
    }
  }
  };
  return (
    <form>
    <div className="add-property">
    <div className="form-wrapper" style={{ boxShadow: '0px 1px 3px -1px', marginTop: '12px', borderRadius: '9px' }}>
      <span className="form-span">Sub Listing</span>
      <div className="form-control">
      {inputFields.map((inputField, index) => (
        
        <div key={index}>
  <div className='from-plan'>
    <div>
      <label className='form-label'>Plan Inable:</label>
      <input
        className='form-input' 
        style={{display:'flex',width:'340px'}}
        type="text"
        name="plan_inable"
        value={inputField.title}
        onChange={(event) => handleInputChange(index, event)}
        placeholder="plan_inable"
      />
       {formErrors.plan_inable && (
              <span className="error-app" >{formErrors.plan_inable}</span>
            )}
      </div>
       <button
           className='form-crose' style={{height:'0px'}}
            onClick={() => handleRemoveInputField(index)}
          >
            ×
          </button>
          </div>
      <div className="form-event" style={{gap:'55px',marginTop:'12px'}} >
          <div>
            <label className="form-label">Bedrooms:</label>
            <input
              className="form-title"
              type="number"
              name="bedrooms"
              value={inputField.bedrooms}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="bedrooms"
            />
          </div>
          <div>
            <label className="form-label">Bathrooms:</label>
            <input
              className="form-title"
              type="number"
              name="bathrooms"
              value={inputField.bthrooms}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="bthrooms"
            />
              </div>
          </div>
          <div className="form-event" style={{gap:'55px',marginTop:'12px'}} >
          <div>
            <label className="form-label">Property Title:</label>
            <input
              className="form-title"
              type="text"
              name="property_title"
              value={inputField.property_title}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="property_title"
            />
              {formErrors.property_title && (
              <span className="error-app" >{formErrors.property_title}</span>
            )}
          </div>
          <div>
            <label className="form-label">Postfix:</label>
            <input
              className="form-title"
              type="text"
              name="postfix"
              value={inputField.postfix}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="postfix"
            />
              </div>
          </div>
          <div className="form-event" style={{gap:'55px',marginTop:'12px'}} >
          <div>
            <label className="form-label">Price:</label>
            <input
              className="form-title"
              type="text"
              name="price"
              value={inputField.price}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="price"
            />
          </div>
          <div>
            <label className="form-label">Pirce postfix:</label>
            <input
              className="form-title"
              type="text"
              name="price_postfix"
              value={inputField.price_postfix}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="price_postfix"
            />
              </div>
          </div>
          <div className="form-event" style={{gap:'55px',marginTop:'12px'}} >
          <div>
            <label className="form-label">Property Type:</label>
            <input
              className="form-title"
              type="text"
              name="property_type"
              value={inputField.property_type}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="property_type"
            />
             {formErrors.property_type && (
              <span className="error-app" >{formErrors.property_type}</span>
            )}
          </div>
          <div>
            <label className="form-label">Availability:</label>
            <input
              className="form-title"
              type="text"
              name="availability"
              value={inputField.availability}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="availability"
            />
             {formErrors.availability && (
              <span className="error-app" >{formErrors.availability}</span>
            )}
              </div>
          </div>
        
        
        </div>
      ))}
      <button className="add_new" onClick={handleAddInputField}>
        Add New
      </button>
    </div>
      </div>
      <div className="form-wrapper" style={{boxShadow:'0px 1px 3px -1px',marginTop:'12px', borderRadius:'9px'}}>
      <div style={{display: 'flex',
    justifyContent: 'space-between'}}>
<div  className="form-containe">
      <Button
      className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleClick}
      >
        Back
      </Button>
    </div>
    <div className='form-contain'>
      <Button
       className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleNext}
      >
       Next
      </Button>
    </div>
</div>

      </div>
      </div>
      </form>
  )
}

export default SubListing