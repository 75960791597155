import React, { useState } from 'react';
import { Button } from '@mui/material';


const PrivateNote = () => {
    const [privateNote, setPrivateNote] = useState('');

    const handlePrivateNoteChange = (event) => {
      setPrivateNote(event.target.value);
    };
      //button
  const handleClick = () => {
    console.log('Button clicked!');
  };

  return (
    <form>
      <div className="add-property">
        <div className="form-wrapper" style={{ boxShadow: '0px 1px 3px -1px', marginTop: '12px', borderRadius: '9px' }}>
          <span className="form-span">Private Note</span>
          <div  className="form-control">
          <label className='form-label'>Write Private note for this property, it will not display for public:</label>

          <textarea
        type="text"
        value={privateNote}
        onChange={handlePrivateNoteChange}
        placeholder="Enter your private note"
        style={{ width: '100%', height: '150px', padding: '10px' }}
      />
    </div>
        </div>
        <div className="form-wrapper" style={{boxShadow:'0px 1px 3px -1px',marginTop:'12px', borderRadius:'9px'}}>
      <div style={{display: 'flex',
    justifyContent: 'space-between'}}>
<div  className="form-containe">
      <Button
      className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleClick}
      >
        Cancel
      </Button>
    </div>
    <div className='form-contain'>
      <Button
       className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleClick}
      >
       Next
      </Button>
    </div>
</div>

      </div>
      </div>
    </form>
  )
}

export default PrivateNote