import React, { useState } from "react";
import { Button } from "@mui/material";
function AddProperty() {
  const [formErrors, setFormErrors] = useState({});

  const [formData, setFormData] = useState({
    property_title: "",
    action: "",
    content: "",
    property_type: "",
    status: "",
    label: "",
    sale_rent_price: "",
    price_prefix: "",
    after_price: "",
    second_price: "",
  });
  const [success, setSuccess] = useState(null);
  const [checkboxes, setCheckboxes] = useState([
    { id: 1, label: "Enable Price Placeholder", checked: false },
  ]);

  const handlevalueChange = (id) => {
    setCheckboxes((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.id === id
          ? { ...checkbox, checked: !checkbox.checked }
          : checkbox
      )
    );
  };

  const handleChange = (event) => {
    if (event.target.type === "file") {
      setFormData({ ...formData, [event.target.name]: event.target.files[0] });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  const handleClick = () => {
    const newInputField = {
      id: Date.now(),
      label: "",
      value: "",
    };

    setFormData((prevFormData) => ({
      ...prevFormData,
      [newInputField.id]: newInputField,
    }));

    console.log("New input field created!");
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.property_title) {
      errors.property_title = "Property Title is required";
    }
    if (!formData.content) {
      errors.content = "Content is required";
    }
    if (!formData.property_type) {
      errors.property_type = "Property Type is required";
    }
    if (!formData.status) {
      errors.status = "Status is required";
    }
    // Add additional validations as needed

    return errors;
  };

  const handleNext = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors); // Set errors if validation fails
    } else {
      console.log("Form data submitted:", formData);

      const formDataObj = new FormData();

      debugger;
      formDataObj.append("property_title", formData.property_title);
      formDataObj.append("action", formData.action);
      formDataObj.append("content", formData.content);
      formDataObj.append("property_type", formData.property_type);
      formDataObj.append("status", formData.status);
      formDataObj.append("label", formData.label);
      formDataObj.append("sale_rent_price", formData.sale_rent_price);
      formDataObj.append("price_prefix", formData.price_prefix);
      formDataObj.append("after_price", formData.after_price);
      formDataObj.append("second_price", formData.second_price);
      console.log("Form data:", [...formDataObj.entries()]);
      const requestOptions = {
        method: "POST",
        body: formData,
        headers: { "Content-Type": "multipart/form-data" },
        redirect: "follow",
      };

      const response = await fetch(
        "https://3e9b-139-135-43-100.ngrok-free.app/",
        requestOptions
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Property added successfully", result.message);
        setSuccess(result.message);
        // Reset form fields
        setFormData({
          property_title: "",
          action: "",
          content: "",
          property_type: "",
          status: "",
          label: "",
          sale_rent_price: "",
          price_prefix: "",
          after_price: "",
          second_price: "",
        });
      } else {
        const result = await response.text();
        console.error("Error adding property", result);
        setSuccess(null);
      }
    }
  };

  return (
    <form>
      <div className="add-property">
        <div className="form-wrapper" style={{ boxShadow: "0px 1px 3px -1px" }}>
          <div className="form-control">
            <label className="form-label">Property Title:</label>
            <input
              className="form-input"
              type="text"
              name="property_title"
              required
              placeholder="Enter your the property title"
              value={formData.property_title}
              onChange={handleChange}
            />
            {formErrors.property_title && (
              <span className="error">{formErrors.property_title}</span>
            )}
          </div>
          <div className="form-control">
            <label className="form-label">Content:</label>
            <textarea
              className="form-input"
              style={{ display: "flex", height: "165px" }}
              type="text"
              required
              name="content"
              placeholder="Desciption"
              value={formData.content}
              onChange={handleChange}
            />
            {formErrors.content && (
              <span className="error">{formErrors.content}</span>
            )}
          </div>
          <div className="form-control">
            <label className="form-label">Property Type:</label>
            <select
              className="form-input"
              name="property_type"
              required
              value={formData.property_type}
              onChange={handleChange}
            >
              <option value="">Select Type</option>
              <option value="Office">-Office</option>
              <option value="Shop">-Shop</option>
              <option value="Warehouse">-Warehouse</option>
              <option value="Residential">Residential</option>
              <option value="Apartment">-Apartment</option>
              <option value="Condo">-Condo</option>
              <option value="Multi Family Home">-Multi Family Home</option>
              <option value="Single Family Hom">-Single Family Home</option>
              <option value="Studio">-Studio</option>
              <option value="Villa">-Villa</option>
            </select>
            {formErrors.property_type && (
              <span className="error">{formErrors.property_type}</span>
            )}
          </div>
          <div className="form-control">
            <label className="form-label">Status:</label>
            <select
              className="form-input"
              name="status"
              required
              value={formData.status}
              onChange={handleChange}
            >
              <option value="">Select Status</option>
              <option value="For Rent">For Rent</option>

              <option value="For Sale">For Sale</option>
            </select>
            {formErrors.status && (
              <span className="error">{formErrors.status}</span>
            )}
          </div>
          <div className="form-control">
            <label className="form-label">Label:</label>
            <select
              className="form-input"
              name="label"
              value={formData.label}
              onChange={handleChange}
            >
              <option value="">Select Label</option>
              <option value="Golden Offer">Golden Offer</option>
              <option value="Hot Offer">Hot Offer</option>
              <option value="Open House">Open House</option>
              <option value="Sold">Sold</option>
            </select>
          </div>
        </div>
        <div
          className="form-wrapper"
          style={{ boxShadow: "0px 1px 3px -1px", marginTop: "12px" }}
        >
          <div className="form-control">
            <label className="form-label">Sale or Rent Price:</label>
            <input
              className="form-input"
              type="number"
              name="sale_rent_price"
              placeholder="Enter the price"
              value={formData.sale_rent_price}
              min={0}
              onChange={handleChange}
            />
          </div>
          <div>
            <div className="form-control" style={{ display: "flex" }}>
              {checkboxes.map((checkbox) => (
                <div key={checkbox.id}>
                  <input
                    type="checkbox"
                    checked={checkbox.checked}
                    onChange={() => handlevalueChange(checkbox.id)}
                  />{" "}
                  {checkbox.label}
                </div>
              ))}
            </div>
          </div>

          <div>
            <div className="form-control">
              <label className="form-label">Price Prefix:</label>
              <input
                className="form-input"
                type="text"
                name="price_prefix"
                placeholder="Enter your the price prefix"
                value={formData.price_prefix}
                onChange={handleChange}
              />
            </div>
          </div>
          <div>
            <div className="form-control">
              <label className="form-label">After The Price:</label>
              <input
                type="number"
                className="form-input"
                name="after_price"
                placeholder="Enter your the after price"
                value={formData.after_price}
                min={0}
                onChange={handleChange}
              />
            </div>
          </div>
          <div>
            <div className="form-control">
              <label className="form-label">Second price:</label>
              <input
                className="form-input"
                type="number"
                min={0}
                name="second_price"
                placeholder="Enter your the second price"
                value={formData.second_price}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* <div className="form-control">
        {/* {Object.keys(formData).map((key) => (
          <div key={key}>
            <label className="form-label">{formData[key].label}</label>
            <input
              className="form-input"
              type="text"
              name={key}
              value={formData[key].value}
              onChange={handleChange}
            />
          </div>
        ))}
      </div> */}
        </div>
        <div
          className="form-wrapper"
          style={{
            boxShadow: "0px 1px 3px -1px",
            marginTop: "12px",
            borderRadius: "9px",
          }}
        >
          <div className="btn-container" style={{ display: "flex" }}>
            <div>
              <Button
                className="form-button"
                variant="contained"
                color="primary"
                size="lg"
                disabled={false}
                onClick={handleClick}
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                className="form-button"
                variant="contained"
                color="primary"
                size="lg"
                disabled={false}
                onClick={handleNext}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default AddProperty;
