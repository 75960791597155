import React, { useState } from 'react';
import { redirect } from 'react-router-dom';

const SignUpPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [accountType, setAccountType] = useState('');
  const [gdprAgreement, setGdprAgreement] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSignUp = async () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (!gdprAgreement) {
      setError("Please agree to the Terms and Conditions");
      return;
    }

    try {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);
      formData.append('account_type', accountType);

      const requestOptions = {
        method: 'POST',
        body: formData,
        redirect: 'follow',
      };

      const response = await fetch('https://9eb7-139-135-43-100.ngrok-free.app/users', requestOptions);

      if (response.ok) {
        const result = await response.json();
        console.log('Account created successfully', result.message);
        setSuccess(result.message);
        setError(null);
        setUsername('');
        setPassword('');
        setConfirmPassword('');
        setAccountType('');
        setGdprAgreement(false);
      } else {
        const result = await response.text();
        setError(result);
        setSuccess(null);
      }
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'account_type') {
      setAccountType(value);
    }
  };

  const handleGdprAgreementChange = (event) => {
    setGdprAgreement(event.target.checked);
  };

  return (
    <div className="login-page customPageWIdth section-margin" id="userSignup">
      <div className="form-wrapper">
        <div className="form-control">
          <label className="form-label" htmlFor="username">Username</label>
          <input
            className="form-input"
            id="username"
            type="text"
            placeholder="Enter Your Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label className="form-label" htmlFor="password">Password</label>
          <input
            className="form-input"
            id="password"
            type="password"
            placeholder="Enter Your Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label className="form-label" htmlFor="c-password">Confirm Password</label>
          <input
            className="form-input"
            id="c-password"
            type="password"
            placeholder="Confirm Your Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label className="form-label">Select Your Account Type:</label>
          <select
            className="form-input"
            name="account_type"
            required
            value={accountType}
            onChange={handleChange}
          >
            <option value="">Select Type</option>
            <option value="Office">Homeowner</option>
            <option value="Shop">Loan Agent</option>
            <option value="Warehouse">Lawyer</option>
          </select>
        </div>
        <div className="form-controller" style={{marginLeft:"45px", marginTop:"12px", display:"flex", gap:"25px", alignItems:"center"}}>
          <input
            type="checkbox"
            checked={gdprAgreement}
            onChange={handleGdprAgreementChange}
            id="gdpr-agreement"
          />
          <label htmlFor="gdpr-agreement" >
            By submitting this form, I agree to the <a href="#" target="_blank">Terms and Conditions</a>
          </label>
        </div>
        <div className="form-control">
          <button type="button" className="form-submit" onClick={handleSignUp}>
            Sign Up
          </button>
        </div>

        {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
        {success && <p style={{ color: 'green', textAlign: 'center' }}>{success}</p>}
      </div>
    </div>
  );
};

export default SignUpPage;