import React, { useState } from 'react';
import { Button } from '@mui/material';

const FloorPlan = () => {
  const [formErrors, setFormErrors] = useState({});

  const [inputFields, setInputFields] = useState([
    {
      plan_title: '',
      bedrooms: '',
      bathrooms: '',
      price: '',
      price_postfix: '',
      plan_inable: '',
      description: '',
      plan_image: null,
    },
  ]);
  const [success, setSuccess] = useState(null);
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputFields = [...inputFields];
    newInputFields[index][name] = value;
    setInputFields(newInputFields);
  };

  const handleImageChange = (index, event) => {
    const newInputFields = [...inputFields];
    newInputFields[index].image = event.target.files[0];
    setInputFields(newInputFields);
  };

  const handleAddInputField = () => {
    const newInputField = {
      plan_title: '',
      bedrooms: '',
      bathrooms: '',
      price: '',
      price_postfix: '',
      plan_inable: '',
      description: '',
      plan_image: null,
    };
    setInputFields([...inputFields, newInputField]);
  };

  const handleRemoveInputField = (index) => {
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
  };

  const handleClick = () => {
    console.log('Button clicked!');
  };
  const inputField = {
    plan_inable: '',
    plan_image: '',
    description: ''
  };
  const validateForm = () => {
    const errors = {};
    if (!inputField.plan_inable) {
      errors.plan_inable = "plan_inable is required";
    }
    if (!inputField.plan_image) {
      errors.plan_image = "plan_image is required";
    }
    if (!inputField.description) {
      errors.description = "description is required";
    }
   
    // Add additional validations as needed
  
    return errors;
  };
  const handleNext = async (event) => {
    event.preventDefault();
   
    const errors = validateForm(inputField);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors); // Set errors if validation fails
    } 
    else {
      console.log("Form data submitted:", inputField);

    const formDataObj = new FormData();
    inputFields.forEach((inputField, index) => {
      formDataObj.append(`plan_title_${index}`, inputField.plan_title);
      formDataObj.append(`bedrooms_${index}`, inputField.bedrooms);
      formDataObj.append(`bathrooms_${index}`, inputField.bathrooms);
      formDataObj.append(`price_${index}`, inputField.price);
      formDataObj.append(`price_postfix_${index}`, inputField.price_postfix);
      formDataObj.append(`plan_inable_${index}`, inputField.plan_inable);
      formDataObj.append(`description_${index}`, inputField.description);
      formDataObj.append(`plan_image_${index}`, inputField.plan_image);
    });
    console.log('Form data:', [...formDataObj.entries()]);
    const requestOptions = {
      method: 'POST',
      body: formDataObj,
      headers: { 'Content-Type': 'multipart/form-data' },
      redirect: 'follow',
    };
  
    const response = await fetch('https://9eb7-139-135-43-100.ngrok-free.app/floorplans', requestOptions);
  
    if (response.ok) {
      const result = await response.json();
      console.log('Property added successfully', result.message);
      setSuccess(result.message);
      // Reset form fields
      setInputFields([
        {
          plan_title: '',
          bedrooms: '',
          bathrooms: '',
          price: '',
          price_postfix: '',
          plan_inable: '',
          description: '',
          plan_image: null,
        },
      ]);
    } else {
      const result = await response.text();
      console.error('Error adding property', result);
      setSuccess(null);
    }
  }
  };

  return (
    <form>
      <div className="add-property">
        <div className="form-wrapper" style={{ boxShadow: '0px 1px 3px -1px', marginTop: '12px', borderRadius: '9px' }}>
          <span className="form-span">Floor Plan</span>
          <div className="form-control">
            {inputFields.map((inputField, index) => (
              <div key={index}>
                <div className='from-plan'>
                  <div>
                    <label className='form-label'>Plan Title:</label>
                    <input
                      className='form-input'
                      style={{ display: 'flex', width: '340px' }}
                      type="text"
                      name="plan_inable"
                      value={inputField.plan_inable}
                      onChange={(event) => handleInputChange(index, event)}
                      placeholder="plan_inable"
                    />
                    {formErrors.plan_inable && (
              <span className="error-app" >{formErrors.plan_inable}</span>
            )}
                  </div>
                  <button
                    className='form-crose' style={{ height: '0px' }}
                    onClick={() => handleRemoveInputField(index)}
                  >
                    ×
                  </button>
                </div>
                <div className="form-event" style={{ gap: '55px', marginTop: '12px' }}>
                  <div>
                    <label className="form-label">Bedrooms:</label>
                    <input
                      className="form-title"
                      type="decimal"
                      name="bedrooms"
                      value={inputField.bedrooms}
                      onChange={(event) => handleInputChange(index, event)}
                      placeholder="Enter bedrooms"
                    />
                  </div>
                  <div>
                    <label className="form-label">Bathrooms:</label>
                    <input
                      className="form-title"
                      type="number"
                      name="bathrooms"
                      value={inputField.bathrooms}
                      onChange={(event) => handleInputChange(index, event)}
                      placeholder="bathrooms"
                    />
                  </div>
                </div>
                <div className="form-event" style={{ gap: '55px', marginTop: '12px' }}>
                  <div>
                    <label className="form-label">Price:</label>
                    <input
                      className="form-title"
                      type="text"
                      name="price"
                      value={inputField.price}
                      onChange={(event) => handleInputChange(index, event)}
                      placeholder="price"
                    />
                  </div>
                  <div>
                    <label className="form-label">Price postfix:</label>
                    <input
                      className="form-title"
                      type="text"
                      name="price_postfix"
                      value={inputField.price_postfix}
                      onChange={(event) => handleInputChange(index, event)}
                      placeholder="price_postfix"
                    />
                  </div>
                </div>
                <div style={{ marginTop: '12px' }}>
                  <label className='form-label'>Plan Title:</label>
                  <input
                    className='form-input'
                    style={{ display: 'flex', width: '340px' }}
                    type="text"
                    name="plan_title"
                    value={inputField.plan_title}
                    onChange={(event) => handleInputChange(index, event)}
                    placeholder="plan_title"
                  />
                </div>
                <div style={{ marginTop: '12px' }}>
                  <label className='form-label'>Plan Image:</label>
                  <input
                    style={{ display: 'flex', width: '230px', height: "45px", padding: '14px' }}
                    type="file"
                    accept="image/*"
                    value={inputField.plan_image}
                    onChange={(event) => handleImageChange(index, event)}
                    placeholder="Selected Image"
                  />
                  {formErrors.plan_image && (
              <span className="error-app" >{formErrors.plan_image}</span>
            )}
                  {inputField.image && (
                    <img src={URL.createObjectURL(inputField.image)} alt="Selected Image" placeholder="Selected Image" />
                  )}
                </div>
                <label className='form-label'>Description:</label>
                <textarea
                  className='form-input'
                  style={{ display: 'flex', height: '165px' }}
                  type="text"
                  name="description"
                  placeholder="Enter the Plan Description"
                  value={inputField.description}
                  onChange={(event) => handleInputChange(index, event)}
                />
                {formErrors.description && (
              <span className="error-app" >{formErrors.description}</span>
            )}
              </div>
            ))}
            <button className="add_new" onClick={handleAddInputField}>
              Add New
            </button>
          </div>
        </div>
        <div className="form-wrapper" style={{ boxShadow: '0px 1px 3px -1px', marginTop: '12px', borderRadius: '9px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="form-containe">
              <Button
                className='form-button'
                variant="contained"
                color="primary"
                size="lg"
                disabled={false}
                onClick={handleClick}
              >
                Back
              </Button>
            </div>
            <div className='form-contain'>
              <Button
                className='form-button'
                variant="contained"
                color="primary"
                size="lg"
                disabled={false}
                onClick={handleNext}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FloorPlan;