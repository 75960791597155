import React from "react";
import Flickity from "react-flickity-component";

const FeatureProperty = () => {
  const flickityOptions = {
    initialIndex: 0,
    cellAlign: "left",
    contain: true,
    pageDots: true,
    wrapAround: true,
  };
  const filtersHeading = "Filters Heading"; // replace with your desired value

  console.log("--");
  const properties = [
    {
      image:
        "https://www.telegraph.co.uk/content/dam/Travel/hotels/2020/march/soneva-jani-water-retreat--bedroom-with-sea-view-pool-by-.jpg?imwidth=1280&imdensity=2",
      price: "$200K",
      beds: 2,
      baths: 2,
      sqft: 1500,
      address: "355 s Oxnard blvd",
    },
    {
      image:
        "https://www.telegraph.co.uk/content/dam/Travel/hotels/2020/march/soneva-jani-water-retreat--bedroom-with-sea-view-pool-by-.jpg?imwidth=1280&imdensity=2",
      price: "$200K",
      beds: 2,
      baths: 2,
      sqft: 1500,
      address: "355 s Oxnard blvd",
    },
    {
      image:
        "https://www.telegraph.co.uk/content/dam/Travel/hotels/2020/march/soneva-jani-water-retreat--bedroom-with-sea-view-pool-by-.jpg?imwidth=1280&imdensity=2",
      price: "$200K",
      beds: 2,
      baths: 2,
      sqft: 1500,
      address: "355 s Oxnard blvd",
    },
    {
      image:
        "https://www.telegraph.co.uk/content/dam/Travel/hotels/2020/march/soneva-jani-water-retreat--bedroom-with-sea-view-pool-by-.jpg?imwidth=1280&imdensity=2",
      price: "$200K",
      beds: 2,
      baths: 2,
      sqft: 1500,
      address: "355 s Oxnard blvd",
    },
  ];

  const section = {
    settings: {
      heading: "Looking for...",
    },
  };
  return (
    <>
      {/* <div className="page-topbar customPageWIdth section-margin">
  <div className="searchbar">
    <div className="search-icon">
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 50 50">
        <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"></path>
      </svg>
    </div>
    <input type="search" placeholder="Enter Address or Zip Code" />
  </div>

  <div className="searchbar-filters">
    <div className="filter-wrapper" id="filters">
      <div className="filter">
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 80">
          <g>
            <g>
              <path d="M56,21H23.3c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3H56c0.7,0,1.3,0.6,1.3,1.3S56.7,21,56,21z" />
            </g>
            <g>
              <path d="M13.1,21H8c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3h5.1c0.7,0,1.3,0.6,1.3,1.3S13.9,21,13.1,21z" />
            </g>
            <g>
              <path d="M56,33.3h-8.6c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3H56c0.7,0,1.3,0.6,1.3,1.3C57.3,32.7,56.7,33.3,56,33.3z" />
            </g>
            <g>
              <path d="M37.2,33.3H8c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3h29.2c0.7,0,1.3,0.6,1.3,1.3C38.5,32.7,37.9,33.3,37.2,33.3z" />
            </g>
            <g>
              <path d="M56,45.7H23.3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3H56c0.7,0,1.3,0.6,1.3,1.3C57.3,45.1,56.7,45.7,56,45.7z" />
            </g>
            <g>
              <path d="M13.1,45.7H8c-0.7,0-1.3-0.6-1.3-1.3C6.7,43.6,7.3,43,8,43h5.1c0.7,0,1.3,0.6,1.3,1.3C14.5,45.1,13.9,45.7,13.1,45.7z" />
            </g>
            <g>
              <path d="M18.2,26.1c-3.5,0-6.4-2.9-6.4-6.4s2.9-6.4,6.4-6.4s6.4,2.9,6.4,6.4S21.8,26.1,18.2,26.1z M18.2,15.9 c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8C22,17.6,20.3,15.9,18.2,15.9z" />
            </g>
            <g>
              <path d="M42.3,38.4c-3.5,0-6.4-2.9-6.4-6.4s2.9-6.4,6.4-6.4c3.5,0,6.4,2.9,6.4,6.4S45.9,38.4,42.3,38.4z M42.3,28.2 c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8C46.1,29.9,44.4,28.2,42.3,28.2z" />
            </g>
            <g>
              <path d="M18.2,50.8c-3.5,0-6.4-2.9-6.4-6.4c0-3.6,2.9-6.4,6.4-6.4s6.4,2.9,6.4,6.4C24.7,47.9,21.8,50.8,18.2,50.8z M18.2,40.6 c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8C22,42.3,20.3,40.6,18.2,40.6z" />
            </g>
          </g>
        </svg>
      </div>
    </div>

    <div className="filter-wrapper" id="sortby">
      <div className="filter">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
          <path d="M6.293 4.293a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1-1.414 1.414L8 7.414V19a1 1 0 1 1-2 0V7.414L3.707 9.707a1 1 0 0 1-1.414-1.414l4-4zM16 16.586V5a1 1 0 1 1 2 0v11.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 1.414-1.414L16 16.586z" fill="#0D0D0D" />
        </svg>
      </div>
    </div>
  </div>
</div> */}
      <div className="customPageWidth section-margin">
        <div className="section-app">
          <h2 style={{fontSize:"20px"}}>Looking to?</h2>
        </div>
        <div className="filterToggles">
          <button className="toggle">
            <span>Buy</span>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </button>
          <button className="toggle">
            <span>Rent</span>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </button>
          <button className="toggle">
            <span>Vacational Rent</span>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </button>
        </div>
      </div>

      <div className="featured-properties customPageWidth section-margin">
        <div className="section-app">
          <h2 id="Search By Agent" style={{fontSize:"20px"}} >Featured Properties</h2>

          <a href="#" className="seeAll">
            See All{" "}
          </a>
        </div>
        <div className="cards main-carousel">
          <div className="card">
            <div className="card-mainImage">
              <img src="https://www.telegraph.co.uk/content/dam/Travel/hotels/2020/march/soneva-jani-water-retreat--bedroom-with-sea-view-pool-by-.jpg?imwidth=1280&imdensity=2" />
            </div>
            <div className="card-content">
              <p className="card-heading">Modern Office Space</p>
              <div className="tags">
                <div className="tag">Featured</div>
                <div className="tag">Rent</div>
              </div>
              <div className="location card-info" style={{ marginTop: 15 }}>
                <img src="./images/location-icon.png" />

                <p>2208 Southwest Dr, Los Angeles, CA 90043, USA</p>
              </div>
              <div className="more-info card-info">
                <div
                  className="info"
                  style={{ display: "flex", columnGap: 10 }}
                >
                  <img src="./images/double-bed-icon.png" />
                  <p>2</p>
                </div>
                <div
                  className="info"
                  style={{ display: "flex", columnGap: 10 }}
                >
                  <img src="./images/bath-solid.png" />

                  <p>2</p>
                </div>
                <div
                  className="info"
                  style={{ display: "flex", columnGap: 10 }}
                >
                  <img src="./images/square-foot.png" />
                  <p>1900 Sq Ft</p>
                </div>
              </div>
              <div className="card-price">1.9K/mo</div>
            </div>
          </div>
        </div>
      </div>
      {/* <Flickity className={'property-items carousel'} elementType={'div'} options={flickityOptions} reloadOnUpdate={true}> */}

      <div className="search-by-agent customPageWidth section-margin">
        <div className="section-app">
          <h2 id="Search By Agent" style={{fontSize:"20px"}}>Search By Agent</h2>

          <a href="#" className="seeAll">
            See All{" "}
          </a>
        </div>
        <div className=" main-type">
          <Flickity
            className={"property-items carousel"}
            elementType={"div"}
            options={flickityOptions}
            reloadOnUpdate={true}
          >
            <div className="cards main-carousel">
              <div className="card">
                <div className="card-mainImage">
                  <img src="https://www.telegraph.co.uk/content/dam/Travel/hotels/2020/march/soneva-jani-water-retreat--bedroom-with-sea-view-pool-by-.jpg?imwidth=1280&imdensity=2" />
                </div>
                <div className="card-content">
                  <p className="card-heading">Modern Office Space</p>
                </div>
              </div>
            </div>
            <div className="cards main-carousel">
              <div className="card">
                <div className="card-mainImage">
                  <img src="https://www.telegraph.co.uk/content/dam/Travel/hotels/2020/march/soneva-jani-water-retreat--bedroom-with-sea-view-pool-by-.jpg?imwidth=1280&imdensity=2" />
                </div>
                <div className="card-content">
                  <p className="card-heading">Modern Office Space</p>
                </div>
              </div>
            </div>
            <div className="cards main-carousel">
              <div className="card">
                <div className="card-mainImage">
                  <img src="https://www.telegraph.co.uk/content/dam/Travel/hotels/2020/march/soneva-jani-water-retreat--bedroom-with-sea-view-pool-by-.jpg?imwidth=1280&imdensity=2" />
                </div>
                <div className="card-content">
                  <p className="card-heading">Modern Office Space</p>
                </div>
              </div>
            </div>
            <div className="cards main-carousel">
              <div className="card">
                <div className="card-mainImage">
                  <img src="https://www.telegraph.co.uk/content/dam/Travel/hotels/2020/march/soneva-jani-water-retreat--bedroom-with-sea-view-pool-by-.jpg?imwidth=1280&imdensity=2" />
                </div>
                <div className="card-content">
                  <p className="card-heading">Modern Office Space</p>
                </div>
              </div>
            </div>
            <div className="cards main-carousel">
              <div className="card">
                <div className="card-mainImage">
                  <img src="https://www.telegraph.co.uk/content/dam/Travel/hotels/2020/march/soneva-jani-water-retreat--bedroom-with-sea-view-pool-by-.jpg?imwidth=1280&imdensity=2" />
                </div>
                <div className="card-content">
                  <p className="card-heading">Modern Office Space</p>
                </div>
              </div>
            </div>
          </Flickity>
        </div>
      </div>
      {/* </Flickity> */}

      <div className="property-results customPageWidth section-margin">
        <div className="section-heading-custom">
          <h2 id="Property Result" className="result" style={{fontSize:"20px"}}>
          Looking For
          </h2>
        </div>
        <div className="property-items">
          {properties.map((property, index) => (
            <div key={index} className="item">
              <div className="property-image">
                <img src={property.image} />
              </div>
              <div className="property-info">
                <p className="price">{property.price}</p>
                <div className="descriptive">
                  <span>{property.beds} Bed</span>
                  <span>{property.baths} Bath</span>
                  <span>{property.sqft} sq ft</span>
                </div>
                <p className="address">{property.address}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FeatureProperty;
