import React, { useState } from 'react';
import { Button } from '@mui/material';

const Upload = () => {
  const [formErrors, setFormErrors] = useState({});

  const [inputFields, setInputFields] = useState([
    {
      select_and_upload: '',
      image: null,
    },
  ]);

  const [formData, setFormData] = useState({
    videoUrl: '',
  });
  const [ setSuccess] = useState(null);

  const handleImageChange = (event) => {
    const newInputFields = [...inputFields];
    newInputFields[0].image = event.target.files[0];
    setInputFields(newInputFields);
  };


  const handlevalueSubmit = (event) => {
    event.preventDefault();
    // Do something with the video URL here, like playing it in a video player
  };

  const handleVideoUrlChange = (event) => {
    setFormData((prevFormData) => ({ ...prevFormData, videoUrl: event.target.value }));
  };

  const handleClick = () => {
    console.log('Button clicked!');
  };
  
  const inputField = {
    select_and_upload: '',
    videoUrl: ''
  };
  const validateForm = () => {
    const errors = {};
    if (!inputField.select_and_upload) {
      errors.select_and_upload = "select_and_upload is required";
    }
    if (!inputField.videoUrl) {
      errors.videoUrl = "videoUrl is required";
    }
  
   
    // Add additional validations as needed
  
    return errors;
  };
  const handleNext = async (event) => {
    event.preventDefault();
   
    const errors = validateForm(inputField);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors); // Set errors if validation fails
    } 
    else {
      console.log("Form data submitted:", inputField);

    const formDataObj = new FormData();
    formDataObj.append('select_and_upload', formData.select_and_upload);
    formDataObj.append('videoUrl', formData.videoUrl);
  
    console.log('Form data:', [...formDataObj.entries()]);
  
    const requestOptions = {
      method: 'POST',
      body: formDataObj,
      headers: { 'Content-Type': 'multipart/form-data' },
      redirect: 'follow',
    };
  
    const response = await fetch('https://9eb7-139-135-43-100.ngrok-free.app/features', requestOptions);
  
    if (response.ok) {
      const result = await response.json();
      console.log('Property added successfully', result);
      setSuccess(result.message);
  
      // Reset form fields
      setFormData({
        select_and_upload: '',
        videoUrl: '',
      });
    } else {
      const result = await response.text();
      console.error('Error adding property', result);
      setSuccess(null);
    }
  }
  };

  return (
    <>
      <form>
        <div className='add-property'>
          <div className="form-wrapper" style={{ boxShadow: '0px 1px 3px -1px', marginTop: '12px', borderRadius: '9px' }}>
            <span className="form-span">Media</span>

            <div className="form-control">
          <label className='form-label'>Select and Upload:</label>
          <div>
          <input
                  style={{ display: 'flex', width: '230px', height: "45px", padding: '14px' }}
                  type="file"
                  accept="image/*"
                  value={inputFields[0].select_and_upload}
                  onChange={handleImageChange}
                  placeholder="Selected and upload"
                />
                 {formErrors.select_and_upload && (
              <span className="error-app" >{formErrors.select_and_upload}</span>
            )}
                {inputFields[0].image && (
                  <img src={URL.createObjectURL(inputFields[0].image)} alt="Selected Image" placeholder="Selected Image" />
                )}

  
</div>
        </div>
      </div>
      <div className="form-wrapper" style={{ boxShadow: '0px 1px 3px -1px', marginTop: '12px', borderRadius: '9px' }}>
        <div>
          <div className="form-control">
            <div>
              <label className='form-label' htmlFor="video-url">Video URL:</label>
              <form onSubmit={handlevalueSubmit}>
                <input
                  className='form-input'
                  style={{ display: 'flex', width: '340px' }}
                  type="text"
                  id="video-url"
                  value={formData.videoUrl}
                  onChange={handleVideoUrlChange}
                />
                 {formErrors.videoUrl && (
              <span className="error-app" >{formErrors.videoUrl}</span>
            )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="form-wrapper" style={{ boxShadow: '0px 1px 3px -1px', marginTop: '12px', borderRadius: '9px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Button
              className='form-button'
              variant="contained"
              color="primary"
              size="lg"
              disabled={false}
              onClick={handleClick}
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button
              className='form-button'
              variant="contained"
              color="primary"
              size="lg"
              disabled={false}
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  </form>
</>
       
  );
};

export default Upload;