import React, { useState } from "react";
import { FaUserLarge } from "react-icons/fa6";
import { Link } from "react-router-dom";

const FavouritePage = () => {
    const [showSearchForm, setShowSearchForm] = useState(false);

    // Function to toggle form visibility
    const handleLocationClick = () => {
      setShowSearchForm((prevState) => !prevState);
    };
  return (
    <div className="agentsPage customPageWidth section-margin">
      <div className="container-agent">
        <h1 className="page-title">Favourites</h1>
      </div>

      <div className="search-container" onClick={handleLocationClick}>
        <div>
          <input
            type="text"
            placeholder="🔍Type Favorites Name Here....."
            className="search-input"
          />
        </div>
      
      </div>
      {showSearchForm && (
        <div className="search-form-agent">
          <h3 >Find Favourites</h3>
          <input
            type="text"
            className="search-bar-agent"
            placeholder="Type agent name here"
            autoFocus
          />
           <button className="search-button-agent">
      <span>Search Favourites</span>
    </button>

        </div>
        
      )}
      <div className="agents-main-dev"></div>

  
      <div className="block-container">
      <div className="block">
        <h1 className="word">B</h1>
        <div className="end" id="agentName">
        <div class="section-page">
   <img alt="Image of a woman with arms crossed" height="40" src="https://storage.googleapis.com/a1aa/image/YkfUc85PnaTGAyii5pkATXYREvSnLATdBlv9v8FSc68Xc0yJA.jpg" width="40"/>
   <div class="text">
   
    <div className="addproperty-text">
                        <Link to="/ProfilePage" style={{ textDecoration: "none" }}>
                          <span style={{ display: "flex", color: "black" }}>
                          Brittany Watkins
                          </span>
                        </Link>
                      </div>
   </div>
  </div>
        </div>
      </div>

      <div className="block">
        <h1 className="word">m</h1>
        <div className="end" id="LoanName">
        <div class="section-page">
   <img alt="Placeholder image of a person" height="40" src="https://storage.googleapis.com/a1aa/image/APEnmYKwxRJTIZxPSGnPtJ0ApfBARloD83qcoVf3Ob2w4olTA.jpg" width="40"/>
   <div class="text">
   <div className="addproperty-text">
                        <Link to="/ProfilePage" style={{ textDecoration: "none" }}>
                          <span style={{ display: "flex", color: "black" }}>
                          mattsakhaei
                          </span>
                        </Link>
                      </div>
   </div>
  </div>
        </div>
      </div>
      <div className="block">
        <h1 className="word">B</h1>
        <div className="end" id="LoanName">
          <div class="section-app-contect">
          <FaUserLarge className="icon-page"  />
          </div>
          <div className="addproperty-text" style={{display:"flex", alignItems:"center"}}>
                        <Link to="/ProfilePage" style={{ textDecoration: "none" }}>
                          <p className="loan-name">Born Loan</p>
                        </Link>
                      </div>
        </div>
      </div>
    </div>
 
    </div>
  )
}

export default FavouritePage