import React, { useState } from 'react';
import { Button } from '@mui/material';

const Feature = () => {
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
    checkbox7: false,
    checkbox8: false,
    checkbox9: false,
    checkbox10: false,
    checkbox11: false,
    checkbox12: false,
    checkbox13: false
  });

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes((prevCheckboxes) => ({ ...prevCheckboxes, [name]: checked }));
  };
  //button
  const handleClick = () => {
    console.log('Button clicked!');
  };

  return (
    <from>
            <div className='add-property'>
            <div className="form-wrapper" style={{boxShadow:'0px 1px 3px -1px',marginTop:'12px', borderRadius:'9px'}}>
            <span className='form-span'>Features</span>
            <div className="form-checkbox">
        <span>Air Conditioning</span>
        <input
          className="form-target"
          type="checkbox"
          id="checkbox1"
          name="checkbox1"
          value="checkbox1"
          checked={checkboxes.checkbox1}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Gym</span>
        <input
          className="form-target"
          type="checkbox"
          id="checkbox2"
          name="checkbox2"
          value="checkbox2"
          checked={checkboxes.checkbox2}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Laundry</span>
        <input
          className="form-target"
          type="checkbox"
          id="checkbox3"
          name="checkbox3"
          value="checkbox3"
          checked={checkboxes.checkbox3}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Lawn</span>
        <input
          className="form-target"
          type="checkbox"
          id="checkbox4"
          name="checkbox4"
          value="checkbox4"
          checked={checkboxes.checkbox4}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Microwave</span>
        <input
          className="form-target"
          type="checkbox"
          id="checkbox5"
          name="checkbox5"
          value="checkbox5"
          checked={checkboxes.checkbox5}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Outdoor Shower</span>
        <input
          className="form-target"
          type="checkbox"
          id="checkbox6"
          name="checkbox6"
          value="checkbox6"
          checked={checkboxes.checkbox6}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Refrigarter </span>
        <input
          className="form-target"
          type="checkbox"
          id="checkbox7"
          name="checkbox7"
          value="checkbox7"
          checked={checkboxes.checkbox7}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Sauna</span>
        <input
          className="form-target"
          type="checkbox"
          id="checkbox8"
          name="checkbox8"
          value="checkbox8"
          checked={checkboxes.checkbox8}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Swiming Pool</span>
        <input
          className="form-target"
          type="checkbox"
          id="checkbox9"
          name="checkbox9"
          value="checkbox9"
          checked={checkboxes.checkbox9}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>TV Cable</span>
        <input
          className="form-target"
          type="checkbox"
          id="checkbox10"
          name="checkbox10"
          value="checkbox10"
          checked={checkboxes.checkbox10}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>washer</span>
        <input
          className="form-target"
          type="checkbox"
          id="checkbox11"
          name="checkbox11"
          value="checkbox11"
          checked={checkboxes.checkbox11}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>WiFi</span>
        <input
          className="form-target"
          type="checkbox"
          id="checkbox12"
          name="checkbox12"
          value="checkbox12"
          checked={checkboxes.checkbox12}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Window Cverings</span>
        <input
          className="form-target"
          type="checkbox"
          id="checkbox13"
          name="checkbox13"
          value="checkbox13"
          checked={checkboxes.checkbox13}
          onChange={handleChange}
        />
      </div>
</div>
<div className="form-wrapper" style={{boxShadow:'0px 1px 3px -1px',marginTop:'12px', borderRadius:'9px'}}>
      <div style={{display: 'flex',
    justifyContent: 'space-between'}}>
<div  className="form-containe">
      <Button
      className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleClick}
      >
        Cancel
      </Button>
    </div>
    <div className='form-contain'>
      <Button
       className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleClick}
      >
       Next
      </Button>
    </div>
</div>

      </div>
    </div>
   
    </from>
  )
}

export default Feature