import React, { useState, useEffect } from 'react';
import Flickity from 'react-flickity-component';
import { FaMapMarkerAlt, FaBed, FaBath, FaRulerCombined } from 'react-icons/fa';
import 'flickity/css/flickity.css'; // Import Flickity styles
const PropertyResults = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append(
          "Cookie",
          "20dbc4926cf657c1cc5fd0e6a5773b1d=Lahore%2CPakistan%2CPK"
        );
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        const response = await fetch(
          "https://yidestate.com/wp-json/wp/v2/properties?fave_featured=1",
          requestOptions
        );
        const result = await response.json();
        setProperties(result);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchProperties();
  }, []);
  if (loading) {
    return <p>Loading properties...</p>;
  }
  const flickityOptions = {
    initialIndex: 0,
    cellAlign: 'left',
    contain: true,
    pageDots: true,
    wrapAround: true
  };
  return (
    <main>
      {/* <div className="property-results customPageWIdth section-margin">
        <div className="section-heaing-custom">
          <h2 id="looking-for">Looking For</h2>
        </div>
        <Flickity className={'property-items carousel'} elementType={'div'} options={flickityOptions} reloadOnUpdate={true}>
          {properties.map((property, index) => (
            <div className="property-item" key={index} style={{ width: '50%', boxSizing: 'border-box', padding: '10px' }}>
              <div className="property-image">
                <img
                  src={property.thumbnail || "https://via.placeholder.com/400x200"}
                  alt={property.title.rendered}
                />
              </div>
              <div className="property-info">
                <p className="price">{property.property_meta.fave_currency}{property.property_meta.fave_property_price}K</p>
                <div className="descriptive">
                  <span><FaBed /> {property.property_meta.fave_property_bedrooms || 'N/A'} Bed</span>
                  <span><FaBath /> {property.property_meta.fave_property_bathrooms || 'N/A'} Bath</span>
                  <span><FaRulerCombined /> {property.property_meta.fave_property_size || 'N/A'} {property.property_meta.fave_property_size_prefix || 'sq ft'}</span>
                </div>
                <p className="address"><FaMapMarkerAlt /> {property.property_meta.fave_property_map_address[0] || "Address Not Available"}</p>
              </div>
            </div>
          ))}
        </Flickity>
      </div> */}
    </main>
  );
};

export default PropertyResults;





